<nav class="navbar navbar-vertical navbar-expand-md navbar-light bg-white p-2" id="sidenav-main">
  <div class="container-fluid">
    <!-- Toggler -->
    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
      aria-controls="sidenav-collapse-main">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Brand -->
    <a class="navbar-brand pt-0" routerLinkActive="active" [routerLink]="['/dashboard']">
      <img src="./assets/img/brand/swati_logo-removebg-preview.png" class="navbar-brand-img" alt="..." />
    </a>
    <!-- User -->
    <ul class="nav align-items-center d-md-none">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <!-- <a class="nav-link nav-link-icon" role="button" ngbDropdownToggle>
          <i class="ni ni-bell-55"></i>
        </a> -->
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0)">Action</a>
          <a class="dropdown-item" href="javascript:void(0)">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)">Something else here</a>
        </div>
      </li>
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="./assets/img/brand/profile_photo.jpg" />
            </span>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <!-- <a
            routerLinkActive="active"
            [routerLink]="['/user-profile']"
            class="dropdown-item"
          >
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </a> -->
          <!-- <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-settings-gear-65"></i>
            <span>Settings</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-calendar-grid-58"></i>
            <span>Activity</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-support-16"></i>
            <span>Support</span>
          </a> -->
          <div class="dropdown-divider"></div>
          <a (click)="logout()" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </div>
      </li>
    </ul>
    <!-- Collapse -->
    <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
      <!-- Collapse header -->
      <div class="navbar-collapse-header d-md-none">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a routerLinkActive="active" [routerLink]="['/dashboard']">
              <img src="./assets/img/brand/swati_logo-removebg-preview.png" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" (click)="isCollapsed = !isCollapsed">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav">
        <li *ngFor="let menuItem of menuItems" class="{{ menuItem.class }} nav-item">
          <div style="cursor: pointer; color: #000;" class="p-1"
            (click)="sub_menu = sub_menu ? undefined : menuItem.title">
            <a [routerLink]="menuItem.children ? [] : [menuItem.path]" class="nav-link">
              <div class="px-1 position-relative">
                <!-- <div class="col-10 pl-3"> -->
                <!-- <a class="svg-icon"> -->
                <div class="textSize">
                  <i class="ni {{ menuItem.icon }} pr-2"> </i>
                  {{ menuItem.title }}
                  <span *ngIf="menuItem.children && menuItem.children.length" class="col-2 text-right my-2">
                    <!-- <div *ngIf="item.children && item.children.length" class="col-2" (click)="sub_menu = sub_menu ? undefined : item.title"> -->
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </span>
                </div>
                <!-- </div> -->
              </div>
            </a>
            <hr class="m-0 text-center" style="width: 90%" />

            <ul class="navbar-nav ml-2" style="color: #000" *ngIf="sub_menu == menuItem.title">
              <li *ngFor="let menuItem_child of menuItem.children" class="{{ menuItem_child.class }} nav-item">
                <a routerLinkActive="active" [routerLink]="[menuItem_child.path]" class="nav-link mx-2">
                  <div class="textSize">
                    <i class="ni {{ menuItem_child.icon }} pr-2"> </i>
                    {{ menuItem_child.title }}
                  </div>
                </a>

                <!-- <hr class="my-0 text-center" style="width:90% ;"> -->
              </li>
            </ul>
          </div>
          <!-- <a routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link">
    
                      <i class="ni {{menuItem.icon}}"></i>
                      {{menuItem.title}}
                  </a> -->
        </li>
      </ul>
    </div>
  </div>
</nav>