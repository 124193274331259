import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { TokenService } from "./token.service";
import { HttpClient } from "@angular/common/http";
import { CoreapiService } from "./coreapi.service";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  roleId: any;
  permission: any;
  userPermission: any;
  method: any;
  url: any;
  constructor(
    private api: CoreapiService,
    private http: HttpClient,
    private router: Router,
    // private as:AlertService,
    private ts: TokenService
  ) {}

  getList(data: any) {
    return this.http.post(`${environment.url}/auth/getList`, data);
  }

  register(data: any) {
    return this.http.post(`${environment.url}/auth/register`, data);
  }

  authlogin(data: any) {
    return this.http.post(`${environment.url}/auth/authlogin`, data);
  }

  login(data: any) {
    return this.http.post(`${environment.url}/auth/login`, data);
  }

  forgetPassword(data: any) {
    return this.http.post(`${environment.url}/auth/forget-password`, data);
  }

  resetPassword(data: any) {
    return this.http.post(`${environment.url}/auth/reset-password`, data);
  }
  sendOtp(data: any) {
    return this.http.post(`${environment.url}/auth/sendotp`, data);
  }
  verifyOtp(data: any) {
    return this.http.post(` ${environment.url}/auth/getOtpVerification`, data);
  }

  updateById(data: any) {
    return this.http.post(`${environment.url}/auth/updateById`, data);
  }

  isLoggedIn(): boolean {
    if (this.ts.getToken()) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  logout() {
    this.cleanUserData();
    this.router.navigate(["/login"]);
  }

  cleanUserData() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refershToken");
    // this.router.navigate(["/"]);
  }

  // getPermission(id: any, method: any, url: any) {
  //   this.url = url;
  //   this.method = method;

  //   this.api.getById("user", id).subscribe((userResp) => {
  //     this.roleId = userResp.role;
  //     this.userPermission = userResp.permission;

  //     this.api.getById("role", this.roleId).subscribe((roleResp) => {
  //       this.permission = roleResp.permission;

  //       const checkPermission = (permissions, targetUrl, targetMethod) => {
  //         return permissions.some((permission) => {
  //           return (
  //             permission.module.includes(targetUrl) &&
  //             permission.module === targetUrl &&
  //             permission.permissions.some(
  //               (control) =>
  //                 control.module === targetMethod && control.isGranted === true
  //             )
  //           );
  //         });
  //       };

  //       const userHasRolePermission = checkPermission(
  //         this.permission,
  //         url,
  //         method
  //       );
  //       const userHasAccessPermission = checkPermission(
  //         this.userPermission,
  //         url,
  //         method
  //       );

  //       if (!userHasAccessPermission && !userHasRolePermission) {
  //         alert("You don't have permission");
  //         return false;
  //       }

  //       return true;
  //     });
  //   });
  // }

  getPermission(id: any, method: any, url: any) {
    this.url = url;
    this.method = method;

    return new Observable((observer) => {
      this.api.getById("user", id).subscribe((userResp) => {
        this.roleId = userResp.role;
        this.userPermission = userResp.permission;

        this.api.getById("role", this.roleId).subscribe((roleResp) => {
          this.permission = roleResp.permission;

          const extractPermissions = (permissionsArray, source) => {
            return permissionsArray
              .filter((permission) => permission.module === url)
              .map((permission) => {
                return {
                  module: permission.module,
                  permissions: permission.permissions,
                  source: source,
                };
              });
          };

          const userPermissions = extractPermissions(
            this.userPermission,
            "user"
          );
          const rolePermissions = extractPermissions(this.permission, "role");

          const permissionsObject = [...userPermissions, ...rolePermissions];

          observer.next(permissionsObject);
          observer.complete();
        });
      });
    });
  }
}
