import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  children?: Array<any>;
}
export const SUPERADMINROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "ni-tv-2 text-primary",
    class: "",
  },
  {
    path: "/inventory/inventory-list",
    title: "Inventory",
    icon: "ni-collection text-primary",
    class: "",
    // children: [
    //   // {
    //   //   path: "/inventory/inventory-create/create",
    //   //   title: "Inventory Create & Details",
    //   //   icon: "ni-collection text-primary",
    //   //   class: "",
    //   // },
    //   {
    //     path: "/inventory/inventory-list",
    //     title: "Inventory Create & Details",
    //     icon: "ni-collection text-primary",
    //     class: "",
    //   },
    // ],
  },
  {
    path: "**",
    title: "Master Management",
    icon: "fa fa-database text-primary",
    class: "",
    children: [
      {
        path: "/master/vehicle-list",
        title: "Vehicle Registration",
        icon: "fa fa-solid fa-car text-primary",
        class: "",
      },
      {
        path: "/master/driver-list",
        title: "Driver Registration",
        icon: "ni-delivery-fast text-primary",
        class: "",
      },
      {
        path: "/master/mining-khadaan-list",
        title: "Mining Registration",
        icon: "ni-vector text-primary",
        class: "",
      },
      {
        path: "/master/party-client-list",
        title: "Client Registration",
        icon: "ni-hat-3 text-primary",
        class: "",
      },
      {
        path: "/master/petrol-pump-list",
        title: "Petrol Pump Registration",
        icon: "fa fa-solid fa-gas-pump text-primary",
        class: "",
      },
      {
        path: "/master/supplier-vendor-list",
        title: "Supplier Registration",
        icon: "ni-paper-diploma text-primary",
        class: "",
      },
      {
        path: "/master/material-list",
        title: "Material",
        icon: "ni-planet text-primary",
        class: "",
      },
      {
        path: "/master/miscellaneous-list",
        title: "Miscellaneous Expenses",
        icon: "fas fa-wallet text-primary",
        class: "",
      },
      // {
      //   path: "/master/form-basic/create",
      //   title: "Expenses",
      //   icon: "fas fa-wallet",
      //   class: "",
      // },
    ],
  },

  {
    path: "**",
    title: "User Management",
    icon: "fa fa-users text-primary",
    class: "",
    children: [
      {
        path: "/user-management/role-list",
        title: "Role Management",
        icon: "ni-settings-gear-65 text-primary",
        class: "",
      },
      {
        path: "/user-management/user-list",
        title: "User Registration",
        icon: "ni-single-02 text-primary",
        class: "",
      },
      {
        path: "/user-management/permission-list",
        title: "Excess Permissions",
        icon: "ni-settings-gear-65 text-primary",
        class: "",
      },
    ],
  },
 
  {
    path: "**",
    title: "Payment Mangement",
    icon: "fa far fa-money-bill-alt text-primary",
    class: "",
    children: [
      {
        path: "/payment/client-payment-list",
        title: "Client Payment",
        icon: "ni-hat-3 text-primary",
        class: "",
      },
      {
        path: "/payment/payment-list",
        title: "Supplier Payment",
        icon: "ni-paper-diploma text-primary",
        class: "",
      },
    ],
  },
  {
    path: "**",
    title: "Reports",
    icon: "ni-single-copy-04 text-primary",
    class: "",
    children: [
      {
        path: "/report/client-payment",
        title: "Client Payment Report",
        icon: "ni-single-copy-04 text-primary",
        class: "",
      },

      {
        path: "/report/supplier-payment",
        title: "Supplier Payment Report",
        icon: "ni-single-copy-04 text-primary",
        class: "",
      },
      {
        path: "/report/inventory",
        title: "Inventory Report",
        icon: "ni-single-copy-04 text-primary",
        class: "",
      },
      {
        path: "/report/user",
        title: "User Report",
        icon: "ni-single-copy-04 text-primary",
        class: "",
      },
     
    ],
  },
];
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "ni-tv-2 text-primary",
    class: "",
  },
  {
    path: "/inventory/inventory-list",
    title: "Inventory",
    icon: "ni-collection text-primary",
    class: "",
    // children: [
    //   // {
    //   //   path: "/inventory/inventory-create/create",
    //   //   title: "Inventory Create & Details",
    //   //   icon: "ni-collection text-primary",
    //   //   class: "",
    //   // },
    //   {
    //     path: "/inventory/inventory-list",
    //     title: "Inventory Create & Details",
    //     icon: "ni-collection text-primary",
    //     class: "",
    //   },
    // ],
  },
  {
    path: "**",
    title: "Master Management",
    icon: "fa fa-database text-primary",
    class: "",
    children: [
      {
        path: "/master/vehicle-list",
        title: "Vehicle Registration",
        icon: "mx-4 fa fa-solid fa-car text-primary",
        class: "",
      },
      {
        path: "/master/driver-list",
        title: "Driver Registration",
        icon: "ni-delivery-fast text-primary",
        class: "",
      },
      {
        path: "/master/mining-khadaan-list",
        title: "Mining/Khadaan Registration",
        icon: "ni-vector text-primary",
        class: "",
      },
      {
        path: "/master/party-client-list",
        title: "Party/Client Registration",
        icon: "ni-hat-3 text-primary",
        class: "",
      },
      {
        path: "/master/petrol-pump-list",
        title: "Petrol Pump Registration",
        icon: "fa fa-solid fa-gas-pump text-primary",
        class: "",
      },
      {
        path: "/master/supplier-vendor-list",
        title: "Supplier/Vendor Registration",
        icon: "ni-paper-diploma text-primary",
        class: "",
      },
      {
        path: "/master/material-list",
        title: "Material",
        icon: "ni-planet text-primary",
        class: "",
      },
      {
        path: "/master/miscellaneous-list",
        title: "Miscellaneous Expenses",
        icon: "fas fa-wallet text-primary",
        class: "",
      },
      // {
      //   path: "/master/form-basic/create",
      //   title: "Expenses",
      //   icon: "fas fa-wallet",
      //   class: "",
      // },
    ],
  },
 

  // {
  //   path: "**",
  //   title: "User Management",
  //   icon: "<fa fa-solid fa-user text-primary",
  //   class: "",
  //   children: [
  //     {
  //       path: "/user-management/role-list",
  //       title: "Role Management",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/user-management/user-list",
  //       title: "User Registration",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/user-management/permission-list",
  //       title: "User Permission Management",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //   ],
  // },
  // {
  //   path: "**",
  //   title: "Inventory",
  //   icon: "ni-collection text-primary",
  //   class: "",
  //   children: [
  //     {
  //       path: "/inventory/inventory-list",
  //       title: "Inventory List",
  //       icon: "ni-collection text-primary",
  //       class: "",
  //     },
  //   ],
  // },
  // {
  //   path: "**",
  //   title: "Reports",
  //   icon: "ni-tv-2 text-primary",
  //   class: "",
  //   children: [
  //     {
  //       path: "/report/inventory",
  //       title: "Inventory Report",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/report/user",
  //       title: "User Report",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //   ],
  // },
];

export const DEFAULTROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "ni-tv-2 text-primary",
    class: "",
  },
  {
    path: "/inventory/inventory-list",
    title: "Inventory",
    icon: "ni-collection text-primary",
    class: "",
    // children: [
    //   // {
    //   //   path: "/inventory/inventory-create/create",
    //   //   title: "Inventory Create & Details",
    //   //   icon: "ni-collection text-primary",
    //   //   class: "",
    //   // },
    //   {
    //     path: "/inventory/inventory-list",
    //     title: "Inventory Create & Details",
    //     icon: "ni-collection text-primary",
    //     class: "",
    //   },
    // ],
  },
  // {
  //   path: "**",
  //   title: "Master Management",
  //   icon: "ni-tv-2 text-primary",
  //   class: "",
  //   children: [
  //     {
  //       path: "/master/vehicle-list",
  //       title: "Vehicle Registration",
  //       icon: "< fa fa-solid fa-car text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/master/driver-list",
  //       title: "Driver Registration",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/master/mining-khadaan-list",
  //       title: "Mining/Khadaan Registration",
  //       icon: "fa fa-solid fa-pickaxe text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/master/party-client-list",
  //       title: "Party/Client Registration",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/master/petrol-pump-list",
  //       title: "Petrol Pump Registration",
  //       icon: "fa fa-solid fa-gas-pump text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/master/supplier-vendor-list",
  //       title: "Supplier/Vendor Registration",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/master/material-list",
  //       title: "Material",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //   ],
  // },

  // {
  //   path: "**",
  //   title: "User Management",
  //   icon: "<fa fa-solid fa-user text-primary",
  //   class: "",
  //   children: [
  //     {
  //       path: "/user-management/role-list",
  //       title: "Role Management",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/user-management/user-list",
  //       title: "User Registration",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/user-management/permission-list",
  //       title: "User Permission Management",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //   ],
  // },
  // {
  //   path: "**",
  //   title: "Inventory",
  //   icon: "ni-collection text-primary",
  //   class: "",
  //   children: [
  //     {
  //       path: "/inventory/inventory-list",
  //       title: "Inventory List",
  //       icon: "ni-collection text-primary",
  //       class: "",
  //     },
  //   ],
  // },
  // {
  //   path: "**",
  //   title: "Reports",
  //   icon: "ni-tv-2 text-primary",
  //   class: "",
  //   children: [
  //     {
  //       path: "/report/inventory",
  //       title: "Inventory Report",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //     {
  //       path: "/report/user",
  //       title: "User Report",
  //       icon: "ni-tv-2 text-primary",
  //       class: "",
  //     },
  //   ],
  // },
];
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  [x: string]: any;
  public menuItems: any[] | undefined;
  public isCollapsed = true;
  user: any;
  sub_menu: any;

  constructor(private router: Router, private http: HttpClient) {
    const storedUser = localStorage.getItem("user");
    const user = storedUser ? JSON.parse(storedUser) : undefined;

    if (user !== null) {
      this.user = user;
    }
    const token = localStorage.getItem("token");
    if (!(this.user && token)) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    switch (user.roleName) {
      case "Super-Admin":
        this.menuItems = SUPERADMINROUTES.filter((menuItem) => menuItem);
        break;
      case "Operator":
        this.menuItems = ROUTES.filter((menuItem) => menuItem);
        break;
      case "Admin":
        this.menuItems = SUPERADMINROUTES.filter((menuItem) => menuItem);
        break;
      default:
        this.menuItems = DEFAULTROUTES.filter((menuItem) => menuItem);
        break;
    }
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refershToken");
    this.router.navigate(["/login"]);
  }
}
